/**
 * Created by zhaoyue on 16/10/9.
 */

import key from './key'
import { Resource } from '@ekuaibao/fetch'

const printShare = new Resource('/api/v1/print/sharing')

const print = new Resource('/api/v1/print/template')
const print1 = new Resource('/api/v1/print')

//获取分享单据摘要  /api/v1/print/sharing/${id}
export function getPrintInfo(data, done) {
  return {
    type: key.GET_SHAREINFO,
    payload: printShare.GET('/$id', data),
    done
  }
}

export function getPrintTemplateList(ids, done) {
  return {
    type: key.GET_PRINT_TEMPLATE_LIST,
    payload: print.GET('/[ids]', { ids }),
    done
  }
}

export function printJob(data, done) {
  let ids = data.flowId || []
  let withPrivilegeId = data.withPrivilegeId
  let taskName = data.taskName
  const flowId = ids.map(v => v.split(':')[0])
  const { callback } = data
  return {
    type: key.POST_PRINTJOB,
    flowId,
    callback,
    payload: print1.POST('/async/pdf', { ids, withPrivilegeId, taskName }),
    done
  }
}
