/**
 * Created by zhaoyue on 16/10/8.
 */

import { dealPrintData } from '@ekuaibao/lib/lib/lib-util'
// import { showPrintModal } from '../../lib/lib-util'
import showPrintModal from './print-util'
import loadable from '@loadable/component'

import { app as api } from '@ekuaibao/whispered'
import { Fetch } from '@ekuaibao/fetch'
const actions = require('./shareexpinfo-action')

export default [
  {
    id: '@share',
    reducer: () => import('./shareexpinfo-reducer'),
    path: '/share',
    ref: '/',
    onload: () => import('./shareexpinfo-view'),

    'cancel:print:remind'(keys) {
      return Fetch.PUT(`/api/v1/print/confirm/remind/$[${keys}]`)
    },

    'get:print:template'(args) {
      return api.dispatch(actions.getPrintTemplateList(args))
    },

    'get:print:param'(flow) {
      let select = {}
      select[flow.id] = flow
      return dealPrintData([flow.id], select)[0]
    },

    'print:list'(data) {
      const corpId = encodeURIComponent(Fetch.ekbCorpId)
      const lang = encodeURIComponent(Fetch.lang)
      const accessToken = window.__PLANTFORM__ === 'APP' ? '' : '&accessToken=' + encodeURIComponent(Fetch.accessToken)
      let {
        flowId,
        templateId,
        billType,
        privilegeId = '',
        withPrivilegeId = false,
        isAsyn,
        taskName,
        callback,
        isTablePreview,
        showAllFeeType,
        sortByIndex = false
      } = data
      const showAllFeeTypeValue = showAllFeeType ? '&showAllFeeType=true' : ''
      let cFlowId = ''
      // 开了轻共享平台的打印
      if (flowId && flowId.length && flowId[0].includes('~GP')) {
        cFlowId = flowId && flowId.map(v => {
          if(v.endsWith(':')) {
            return v.substr(0, v.length - 1)
          }
          return v
        })
      } else {
        cFlowId = flowId && flowId.map(v => v && v.split(':')[0])
      }
      let pdfDownloadUrl = `${Fetch.fixOrigin(location.origin)}/api/v1/print/pdf/noTemplate/[${encodeURIComponent(
        flowId
      )}]?corpId=${corpId}${showAllFeeTypeValue}&lang=${lang}${accessToken}&withPrivilegeId=${withPrivilegeId}&download=false&privilegeId=${privilegeId}&sortByIndex=${sortByIndex}`

      // 表格预览时，在弹窗内展示打印预览结果
      if (isTablePreview) {
        return api.invokeService('@bills:set:print:preview:url', pdfDownloadUrl)
      }
      //修改成不按模版分组
      if (corpId && flowId) {
        if (isAsyn) {
          return api.dispatch(actions.printJob({ flowId, withPrivilegeId, taskName, privilegeId, callback }))
        } else {
          api.emit('@vendor:open:link', pdfDownloadUrl, false)
          showPrintModal(cFlowId, callback)
        }
      }

      //打印模版预览
      if (templateId && !flowId) {
        templateId = encodeURIComponent(templateId)
        if (privilegeId) {
          pdfDownloadUrl = `${Fetch.fixOrigin(
            location.origin
          )}/api/v1/print/pdf/example?templateId=${templateId}&lang=${lang}${accessToken}&corpId=${corpId}&billType=${billType}&privilegeId=${privilegeId}`
        } else {
          pdfDownloadUrl = `${Fetch.fixOrigin(
            location.origin
          )}/api/v1/print/pdf/example?templateId=${templateId}&lang=${lang}${accessToken}&corpId=${corpId}&billType=${billType}`
        }
        api.emit('@vendor:open:link', pdfDownloadUrl, false)
      }
    }
  },
  {
    path: '/print',
    ref: '/',
    onload: () => import('./share-print-view-router')
  },
  {
    resource: '@print',
    value: {
      ['share-print-view']: loadable(() => import('./share-print-view'))
    }
  }
]
