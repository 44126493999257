import { Fetch } from '@ekuaibao/fetch';
import { showModal } from '@ekuaibao/show-util';
export default function showPrintModal(flowId, fn) {
    showModal.confirm({
        className: 'print-modal-wrapper',
        iconType: 'info-circle',
        title: i18n.get('请确认打印是否已完成？'),
        content: i18n.get('打印预览已在新窗口中打开。如果没有响应，请确认浏览器是否拦截了新窗口'),
        okText: i18n.get('打印完成'),
        cancelText: i18n.get('打印未完成'),
        onOk: function () {
            Fetch.PUT("/api/v1/print/confirm/$[" + flowId + "]").then(function () { return fn && fn(); });
        }
    });
}
