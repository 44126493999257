/**
 * Created by zhaoyue on 16/10/8.
 */

export const ID = '@share'

export default {
  ID: ID,
  PUT_SHAREINFO: `${ID}/PUT_SHAREINFO`,
  GET_SHAREINFO: `${ID}/GET_SHAREINFO`,
  GET_SHAREHTML: `${ID}/GET_SHAREHTML`,
  GET_SHAREPDF: `${ID}/GET_SHAREPDF`,
  GET_PRINT_TEMPLATE_LIST: `${ID}/GET_PRINT_TEMPLATE_LIST`,
  POST_PRINTJOB: `${ID}/POST_PRINTJOB`
}
